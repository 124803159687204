import {
  ApproveIcon,
  Box,
  CancelIcon,
  CONFIG,
  DataGrid,
  DeleteIcon,
  EditIcon,
  GridActionsCellItem,
  GridColDef,
  GridEditInputCell,
  GridEventListener,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridSlots,
  Link,
  MenuItem,
  SaveIcon,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  useGridApiContext,
  useUserRoles,
} from '@sgde/core';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import {
  ResourceDto,
  ResourceIdentifierType,
  ResourceState as ResourceStateEnum,
} from '../../models/dto/chat/resource.ts';
import { useOrganisations } from '../../store/organisationApi.ts';
import { useDeleteResource, useResources, useUpdateResource } from '../../store/resourceApi.ts';
import { ResourceState } from './ResourceState.tsx';
import { useStyles } from './ResourcesTable.styles.ts';
import { Toolbar } from './ResourcesTableToolbar.tsx';

export const ResourcesTable = () => {
  const { classes } = useStyles();
  const { data: resources } = useResources();
  const [updateResource] = useUpdateResource();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 });

  const updateResourceState = (id: GridRowId, newState: ResourceStateEnum) => {
    const resourceToUpdate = resources?.find(resource => resource.id === id);
    if (resourceToUpdate) {
      const updatedResource = { ...resourceToUpdate, state: newState };
      updateResource(updatedResource);
    }
  };

  const { columns } = useColumns({ rowModesModel, setRowModesModel, updateResourceState });

  const processRowUpdate = (newRow: GridRowModel) => {
    updateResource(newRow as ResourceDto);

    return newRow;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  return (
    <DataGrid
      className={classes.root}
      editMode="row"
      columns={columns}
      rows={resources ?? []}
      hideFooterSelectedRowCount
      rowModesModel={rowModesModel}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={processRowUpdate}
      onRowModesModelChange={setRowModesModel}
      isCellEditable={({ row }) => !!row.state}
      isRowSelectable={({ row }) => !!row.state}
      slots={{
        toolbar: Toolbar as GridSlots['toolbar'],
        noRowsOverlay: NoRowsOverlay,
        noResultsOverlay: NoResultsOverlay,
      }}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      slotProps={{ toolbar: { setRowModesModel, setPaginationModel } }}
      columnVisibilityModel={{ identifierType: false }}
    />
  );
};

type IActionsProps = {
  setRowModesModel: Dispatch<SetStateAction<GridRowModesModel>>;
  updateResourceState: (id: GridRowId, newState: ResourceStateEnum) => void;
};
const useActions = ({ setRowModesModel, updateResourceState }: IActionsProps) => {
  const { classes } = useStyles();
  const { hasRole } = useUserRoles();
  const [deleteResource] = useDeleteResource();

  const handleEditClick = (id: GridRowId) => () =>
    setRowModesModel(rowModesModel => ({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } }));

  const handleDeleteClick = (id: GridRowId) => () => deleteResource({ id: id as number });

  const handleApproveClick = (id: GridRowId) => () => updateResourceState(id as number, ResourceStateEnum.Public);

  const handleSaveClick = (id: GridRowId) => () =>
    setRowModesModel(rowModesModel => ({ ...rowModesModel, [id]: { mode: GridRowModes.View } }));

  const handleCancelClick = (id: GridRowId) => () =>
    setRowModesModel(rowModesModel => ({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));

  const getEditActions = (id: GridRowId) => [
    <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(id)} color="inherit" />,
    <GridActionsCellItem icon={<CancelIcon />} label="Cancel" onClick={handleCancelClick(id)} color="inherit" />,
  ];

  const getDefaultActions = (id: GridRowId, row: ResourceDto) => {
    const rowType = ResourceStateEnum[row.state as unknown as keyof typeof ResourceStateEnum];
    const isApproveDisabled = rowType !== ResourceStateEnum.Active;

    if (hasRole('Admin')) {
      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={handleEditClick(id)}
          className={classes.disabled}
          color="inherit"
          disabled={!row.state}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={handleDeleteClick(id)}
          className={classes.disabled}
          color="inherit"
          disabled={!row.state}
        />,
        <Tooltip
          title={
            rowType >= ResourceStateEnum.Active
              ? 'Publica'
              : 'Resursa trebuia sa fie Activa pentru a putea fi publicata'
          }
        >
          <GridActionsCellItem
            icon={<ApproveIcon />}
            label="Approve"
            onClick={handleApproveClick(id)}
            className={classes.disabled}
            color="inherit"
            component={isApproveDisabled ? 'span' : undefined}
            disabled={isApproveDisabled}
          />
        </Tooltip>,
      ];
    }
    return [
      <GridActionsCellItem
        icon={<EditIcon />}
        label="Edit"
        onClick={handleEditClick(id)}
        className={classes.disabled}
        color="inherit"
        disabled={!row.state}
      />,
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label="Delete"
        onClick={handleDeleteClick(id)}
        className={classes.disabled}
        color="inherit"
        disabled={!row.state}
      />,
    ];
  };

  return { getEditActions, getDefaultActions };
};

interface IColumnProps {
  rowModesModel: GridRowModesModel;
  setRowModesModel: Dispatch<SetStateAction<GridRowModesModel>>;
  updateResourceState: (id: GridRowId, newState: ResourceStateEnum) => void;
}
const useColumns = ({ rowModesModel, setRowModesModel, updateResourceState }: IColumnProps) => {
  const { classes, cx } = useStyles();
  const { data: organisations } = useOrganisations();
  const { getDefaultActions, getEditActions } = useActions({ setRowModesModel, updateResourceState });
  const organisationOptions = useMemo(
    () => [{ id: undefined, name: 'Personal' }, ...(organisations?.map(({ id, name }) => ({ id, name })) ?? [])],
    [organisations]
  );

  const columns = [
    {
      field: 'title',
      headerName: 'Titlu',
      disableColumnMenu: true,
      editable: true,
      flex: 3,
      renderCell: ({ row }: GridRenderCellParams<ResourceDto>) => (
        <Link
          className={cx({ [classes.disabledLink]: !row.state })}
          href={`${CONFIG.BASE_API_URL}/chat/resources/${row.id}/download`}
          underline="hover"
          color="inherit"
          target="_blank"
        >
          {row.title}
        </Link>
      ),
    },
    { field: 'author', headerName: 'Autor', disableColumnMenu: true, editable: true, flex: 2 },
    { field: 'identifierType', headerName: 'Tip', disableColumnMenu: true, editable: true },
    {
      field: 'identifier',
      headerName: 'Identificator',
      disableColumnMenu: true,
      editable: true,
      flex: 2.5,
      renderCell: ({ row }: GridRenderCellParams<ResourceDto>) => (
        <>
          {row.identifierType ? <b>{row.identifierType}: </b> : ''}
          <i>{row.identifier}</i>
        </>
      ),
      renderEditCell: props => <IdentifierEditCell {...props} />,
    },
    {
      field: 'state',
      headerName: 'Stare',
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: ({ value }) => <ResourceState state={value} />,
    },
    {
      field: 'organisationIds',
      headerName: 'Organizatii',
      disableColumnMenu: true,
      flex: 3,
      editable: true,
      type: 'custom',
      valueOptions: organisationOptions,
      renderEditCell: props => <OrganisationsEditCell {...props} />,
      valueFormatter: (ids: number[]) =>
        organisationOptions
          .reduce(
            (selected, org) => (org.id && ids?.includes(org.id) ? [...selected, org.name] : selected),
            [] as string[]
          )
          .join(', ') || 'Personal',
    },
    { field: 'addedByUserEmail', headerName: 'Adaugat de', disableColumnMenu: true, flex: 2 },
    {
      field: 'createdAt',
      headerName: 'Adaugat la',
      type: 'date',
      valueGetter: value => new Date(value),
      disableColumnMenu: true,
      flex: 1.5,
    },
    {
      field: 'actions',
      type: 'actions',
      resizable: false,
      getActions: ({ id, row }) =>
        rowModesModel[id]?.mode === GridRowModes.Edit ? getEditActions(id) : getDefaultActions(id, row),
    },
  ] as GridColDef[];

  return {
    columns,
  };
};

const IdentifierEditCell = (props: GridRenderEditCellParams) => {
  const apiRef = useGridApiContext();
  const [identifierValue, setIdentifierValue] = useState(props.row.identifier);
  const handleValueChange = async (_: SelectChangeEvent, newValue: string) => {
    setIdentifierValue(newValue);
    const isbnPattern =
      /^(?=[0-9X]{10}$|(?=(?:[0-9]+[- ]){3})[- 0-9X]{13}$|97[89][0-9]{10}$|(?=(?:[0-9]+[- ]){4})[- 0-9]{17}$)(?:97[89][- ]?)?[0-9]{1,5}[- ]?[0-9]+[- ]?[0-9]+[- ]?[0-9X]$/i;
    const issnPattern = /^\d{4}-\d{3}(\d|X)$/;
    const doiPattern = /^10\.(\d+\.*)+[/](([^\s.])+\.*)+$/i;

    await apiRef.current.setEditCellValue({
      id: props.id,
      field: 'identifierType',
      value: issnPattern.test(newValue)
        ? ResourceIdentifierType.ISSN
        : doiPattern.test(newValue)
          ? ResourceIdentifierType.DOI
          : isbnPattern.test(newValue)
            ? ResourceIdentifierType.ISBN
            : undefined,
    });
  };

  return (
    <GridEditInputCell
      {...props}
      field="identifier"
      value={identifierValue}
      onValueChange={handleValueChange}
      colDef={{ ...props.colDef, field: 'identifier' }}
    />
  );
};

const OrganisationsEditCell = ({ id, value, field, row }: GridRenderEditCellParams) => {
  const apiRef = useGridApiContext();
  const { data: organisations } = useOrganisations();
  const handleChange = (value: number[]) => {
    const orgIds = value?.length ? value.filter(orgId => !!orgId) : [];
    apiRef.current.setEditCellValue({ id, field, value: orgIds });
  };

  return (
    <Select
      disabled={ResourceStateEnum[row.state as unknown as keyof typeof ResourceStateEnum] !== ResourceStateEnum.Active}
      multiple
      value={value}
      onChange={({ target: { value } }) => handleChange(value)}
      fullWidth
    >
      {organisations?.map(org => (
        <MenuItem key={org.id} value={org.id}>
          {org.name}
        </MenuItem>
      ))}
    </Select>
  );
};

const NoRowsOverlay = () => (
  <Stack alignItems="center" justifyContent="center" height="100%">
    <Box>Nu există resurse disponibile. Pentru a adăuga noi resurse, apăsați butonul '+'.</Box>
  </Stack>
);

const NoResultsOverlay = () => (
  <Stack alignItems="center" justifyContent="center" height="100%">
    <Box>Nu au fost găsite resurse.</Box>
  </Stack>
);
