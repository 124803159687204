import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { loginRequest } from '../auth/config.ts';
import { useCurrentUser } from '../store';

export const AuthenticationGateway = () => (
  <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
    <UserValidation />
  </MsalAuthenticationTemplate>
);

const UserValidation = () => {
  const { data: user, isSuccess } = useCurrentUser();

  useEffect(() => {
    if (user?.softDeleted) {
      throw new Error('Contul dumneavoastra nu este activat sau nu exista.');
    }
  }, [user]);

  return isSuccess && !user.softDeleted ? <Outlet /> : <></>;
};
